import { getFormProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { z } from "zod";

import { LoaderCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import ClearIcon from "~/assets/icons/clearIconOutline";
import LanguageSelector, { LANGUAGE_FR } from "~/components/LanguageSelector";
import Logo from "~/components/Logo";
import TextFieldRAC from "~/components/TextField";
import Button from "~/components/Button";
import type { Tokens } from "~/models/users.server";
import { login } from "~/models/users.server";
import i18next from "~/modules/i18next.server";
import { createUserSession, getUserId } from "~/utils/session.server";

const schema = z.object({
  identifier: z.string({
    required_error: "LOGIN_FORM_IDENTIFIER_ERROR_REQUIRED",
  }),
  password: z.string({ required_error: "LOGIN_FORM_PASSWORD_ERROR_REQUIRED" }),
});

export async function loader({ request }: LoaderFunctionArgs) {
  let locale = LANGUAGE_FR;
  try {
    locale = await i18next.getLocale(request);
    const userId = await getUserId(request);
    if (userId) return redirect("/");
  } catch (error: any) {
  } finally {
    return json({ locale });
  }
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema });

  if (submission.status !== "success") {
    return json(submission.reply());
  }

  let tokens: Tokens | null = null;

  try {
    // sign in user with identifier and password
    const resp = await login(submission.value);

    tokens = {
      access: resp.access_token,
      refresh: resp.refresh_token,
      expirationDate: resp.expiration_date,
    };
  } catch (error: any) {
    console.error("Failed to get access token", error);
    if (error instanceof Response && error.status === 401) {
      return json(
        submission.reply({
          formErrors: ["LOGIN_FORM_ERROR_INVALID_PASSWORD_OR_IDENTIFIER"],
        })
      );
    }
    if (error?.code?.startsWith("ECONN")) {
      return json(
        submission.reply({
          formErrors: [
            "Keycloak authentication server is unreachable. Retry later.",
          ],
        })
      );
    }

    return json(
      submission.reply({
        formErrors: [
          "An error occured while trying to connect to Keycloak. Retry later.",
        ],
      })
    );
  }

  // verify response contains access token
  if (!tokens?.access) {
    throw new Error("Something went wrong");
  }

  // create user session
  return createUserSession({
    request,
    tokens,
    remember: false, // TODO: implement remember checkbox
    redirectTo: "/dpcr", // TODO: implement redirectTo hidden field
  });
}

export default function Login() {
  const data = useLoaderData<typeof loader>();
  const lastResult = useActionData<typeof action>();
  const navigation = useNavigation();
  let { t } = useTranslation();

  const [form, { identifier, password }] = useForm({
    lastResult,
    constraint: getZodConstraint(schema),
  });

  return (
    <>
      <title>{t("LOGIN_TITLE_DESCRIPTION")}</title>
      <div className="flex flex-col justify-center items-center min-h-screen p-xl gap-2.5 bg-primary-50">
        <div className="flex flex-col justify-center items-center px-l pt-l pb-xl w-[510px] rounded-m bg-white border border-secondary-main-10">
          <div className="flex flex-col items-center gap-12 w-full">
            <div className="flex flex-row items-start justify-between w-full">
              <Logo />
              <LanguageSelector locale={data?.locale} />
            </div>
          </div>
          <div className="flex flex-col mb-8 mt-12 gap-y-3 text-center w-[300px]">
            <span className="text-primary-900 text-lg font-medium">
              {t("LOGIN_WELCOME_TITLE")}
            </span>
            <span className="text-primary-300 text-sm whitespace-pre-line">
              {t("LOGIN_WELCOME_SUBTITLE")}
            </span>
          </div>
          <Form
            method="POST"
            {...getFormProps(form)}
            className={`flex flex-col gap-y-6 w-[320px]
              ${
                navigation.state === "submitting"
                  ? "pointer-events-none opacity-50"
                  : ""
              }`}
          >
            <div className="flex flex-col gap-y-4">
              <TextFieldRAC
                label={t("LOGIN_FORM_IDENTIFIER_LABEL")}
                name={identifier.name}
                id={identifier.id}
                type="text"
                isInvalid={!!identifier.errors}
                errorMessage={identifier?.errors?.map((m) => t(m)).join(", ")}
                variant={!!identifier.errors ? "error" : "primary"}
              />
              <TextFieldRAC
                label={t("LOGIN_FORM_PASSWORD_LABEL")}
                name={password.name}
                id={password.id}
                type="password"
                isInvalid={!!password.errors}
                errorMessage={password?.errors?.map((m) => t(m)).join(", ")}
                variant={!!password.errors ? "error" : "primary"}
              />
              {form.errors && (
                <div className="flex flex-col gap-y-4">
                  {form.errors.map((m, index) => (
                    <div
                      className="flex flex-row gap-x-2 items-center text-error-main-500 text-xs"
                      key={index}
                    >
                      <ClearIcon />
                      <span>{t(m)}</span>
                    </div>
                  ))}
                </div>
              )}
              {/*
              <div className="flex flex-row justify-end text-secondary-main-50 text-xs">
                <span>{t("LOGIN_FORGOTTEN_PASSWORD_BUTTON")}</span>
              </div>
              */}
            </div>
            <Button variant="primary" type="submit" className="w-full">
              {navigation.state !== "idle" ? (
                <LoaderCircle className="h-4 w-4 spinning" aria-hidden="true" />
              ) : (
                t("LOGIN_FORM_SUBMIT_BUTTON")
              )}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}
